<template>
  <v-dialog :value="visible" @input="cancel()" max-width="360">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        Deseja excluir este registro?
        <v-spacer></v-spacer>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          outlined
          class="no-text-transform"
          color="primary"
          @click="cancel()"
        >
          Não
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn class="no-text-transform" color="primary" @click="confirm()">
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmDeleteData",

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },

    data: {
      type: [Object, String, Number],
      required: true,
      default: null,
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },

    confirm() {
      this.$emit("confirm", this.data);
    },
  },
};
</script>